import React from "react";
import { useTranslation } from "react-i18next";

import { PortalCard } from "./PortalCard";

import "./index.css";

export default function Home() {
    const { t } = useTranslation();
    const cardsData: any = {
        firsContainer: [
            {
                title: "Kiosk application",
                img: "kiosk.png",
                description: " ",
                link: "",
                windowsDownloadLink: "aures-kiosk.exe",
            },
            {
                title: "C&C | Website",
                img: "ccSiteWeb.png",
                description: " ",
                link: "",
                windowsDownloadLink: "",
            },
            {
                title: "C&C | Mobile application",
                img: "ccMobile.png",
                description: "",
                link: "",
                windowsDownloadLink: "",
            },
            {
                title: "KDS | production screen",
                img: "KDS_Prod.png",
                description: "",
                link: "",
                windowsDownloadLink: "",
            },
            {
                title: "KDS | assembly screen",
                img: "KDS_ASSEMBLAGE.png",
                description: "",
                link: "",
                windowsDownloadLink: "",
            },
            {
                title: "Application ORB",
                img: "ORB.png",
                description: "",
                link: "",
                windowsDownloadLink: "",
            },
            {
                title: "Middleware",
                img: "Middleware.png",
                description: "",
                link: "",
                windowsDownloadLink: "aures-middleware.EXE",
            },

            {
                title: "KYRA application",
                img: "KYRA.png",
                description: "",
                link: "",
                windowsDownloadLink: "",
            },
            {
                title: "SCO application",
                img: "SCO.png",
                description: "",
                link: "",
                windowsDownloadLink: "",
            },
        ],
    };

    return (
        <React.Fragment>
            <div className="installer-global-title__clz">
                <h1 className="installer-title__clz">
                    {t("Install applications")}
                </h1>
                <h1>
                    <span className="installer-etk-title__clz">
                        {t("The ETK experience")}
                    </span>
                    <span className="installer-opacity-title__clz">
                        {" "}
                        {t("Do even more with ETK products and services")}
                    </span>{" "}
                </h1>
            </div>
            <div className="installer-cards">
                <div className="installer-container__clz">
                    {cardsData.firsContainer.map((el: any) => (
                        <PortalCard
                            windowsDownloadLink={el.windowsDownloadLink}
                            title={el.title}
                            description={el.description}
                            link={el.link}
                            img={el.img}
                        />
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}
