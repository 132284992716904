import React from "react";
import classnames from "classnames";
import { UncontrolledTooltip } from "reactstrap";
import { useTranslation } from "react-i18next";

import "./index.css";

type PropsType = {
    title: string;
    description: string;
    link: string;
    img: string;
    windowsDownloadLink: string;
};

export function PortalCard({
    title,
    description,
    link,
    img,
    windowsDownloadLink,
}: PropsType) {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <div className={`${classnames("installer-outer_clz")}`}>
                <div className="installer-content__clz pt-4">
                    <h1 className="installer-h1-title__clz">{t(title)}</h1>
                    <p className="installer-p-title__clz">{t(description)}</p>

                    <div className="align-content-stretch flex-wrap installer-download__clz">
                        <div className="installer-download-title__clz installer-divider">
                            {t(title) === t("C&C | Website")
                                ? t("To consult")
                                : t("Download on")}
                        </div>
                        <div className="pl-2 pr-2 installer-divider">
                            {t(title) === t("C&C | Website") ? (
                                <>
                                    <img
                                        id="tooltip"
                                        src="Edge.png"
                                        className="pr-1 valid-icon__clz"
                                        alt=""
                                    />
                                    <img
                                        className="pr-1 valid-icon__clz"
                                        src="chrome.png"
                                        alt=""
                                    />
                                    <img
                                        className="pr-1 valid-icon__clz"
                                        src="firefox.png"
                                        alt=""
                                    />
                                    <img
                                        className="pr-1 valid-icon__clz"
                                        src="safari.png"
                                        alt=""
                                    />
                                </>
                            ) : t(title) === t("C&C | Mobile application") ? (
                                <>
                                    <img
                                        id="tooltip"
                                        src="apple.png"
                                        className="pr-2 invalid-icon__clz"
                                        alt=""
                                    />
                                    <img
                                        id="tooltip"
                                        className="pr-1 invalid-icon__clz"
                                        src="google.png"
                                        alt=""
                                    />
                                </>
                            ) : (
                                <>
                                    {t(title) === t("Kiosk application") ||
                                    t(title) === t("Middleware") ? (
                                        <a
                                            href={`${windowsDownloadLink}`}
                                            download
                                        >
                                            <img
                                                src="windows.png"
                                                className={classnames(
                                                    "pr-1 valid-icon__clz"
                                                )}
                                                alt=""
                                            />
                                        </a>
                                    ) : (
                                        <img
                                            id={"tooltip"}
                                            src="windows.png"
                                            className={classnames(
                                                "pr-1 invalid-icon__clz"
                                            )}
                                            alt=""
                                        />
                                    )}

                                    <img
                                        id="tooltip"
                                        src="android.png"
                                        className="pr-1 invalid-icon__clz"
                                        alt=""
                                    />
                                    <img
                                        id="tooltip"
                                        className="pr-1 invalid-icon__clz"
                                        src="linux.png"
                                        alt=""
                                    />
                                </>
                            )}
                        </div>
                        <div
                            className="installer-footer-title__clz installer-divider"
                            style={{ marginTop: "-15px" }}
                        >
                            |
                        </div>
                        <div
                            className="installer-cart-btn_clz"
                            style={{ cursor: "pointer" }}
                        >
                            {t("User Manual")}
                        </div>
                    </div>
                </div>
                <div className="installer-image__clz">
                    <img src={img} className="img" alt="" />
                </div>
            </div>
            <UncontrolledTooltip
                placement="top"
                target="tooltip"
                style={{ backgroundColor: "#3c3a3a" }}
            >
                {t("Coming soon")}
            </UncontrolledTooltip>
        </React.Fragment>
    );
}
