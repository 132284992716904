export function decryptWord(wordToDecrypt: string): any {
    if (
        wordToDecrypt === "" ||
        wordToDecrypt === undefined ||
        wordToDecrypt === null
    )
        return "";
    let decryptedWord = wordToDecrypt;
    const buff = Buffer.from(decryptedWord, "base64");
    decryptedWord = buff.toString("utf8");
}
