import React from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import "./index.css";

export function Footer() {
    const { t } = useTranslation();

    return (
        <footer
            className="my-selection-footer global-footer__clz"
            style={{ marginTop: "auto", backgroundColor: "rgb(253, 253, 253)" }}
        >
            <div className="divider__clz">
                <hr></hr>
            </div>
            <div className="footer-text__clz justify-content-start">
                <div className="mt-4 mx-1 mb-md-4 px-4">
                    ETK® Marque AURESKONNECT Copyright ©{" "}
                    {new Date().getFullYear()}
                </div>

                <Link to="#" className="mt-4 mx-1 mb-md-4">
                    <u>{t("General Terms of use")}</u>
                </Link>
                <Link to="#" className="mt-4 mx-1 mb-md-4">
                    <u>{t("General condition of sale")}</u>
                </Link>
                <Link to="#" className="mt-4 mx-1 mb-md-4">
                    <u>{t("Confidentiality rules")}</u>
                </Link>
            </div>
        </footer>
    );
}
