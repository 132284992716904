import { cache } from "swr";
import React from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { BsCaretUpFill, BsCaretDownFill } from "react-icons/bs";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

export default function ProfileMenu() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [menu, setMenu] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>("");
    const [userFirstName, setUserFirstName] = React.useState<any>("");
    const [userLastName, setUserLastName] = React.useState<any>("");

    const tokenUserTmp: any = localStorage.getItem("access_token_id");

    const cryptDecryptDataUser = React.useCallback(
        async (wordToDecrypt: string, stateSetter: typeof Function) => {
            return await fetch(
                `${process.env.REACT_APP_ADMIN_ACCESS}/decryptWord`,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: `wordToDecrypted=${wordToDecrypt}`,
                    method: "POST",
                }
            )
                .then((result) => result.text())
                .then((response: any) => {
                    stateSetter(response);
                    return response;
                })
                .catch((error) => {
                    stateSetter("");
                    return "";
                });
        },
        []
    );

    React.useEffect(() => {
        if (tokenUserTmp != null) {
            let dataUserConnected: any = jwt_decode(tokenUserTmp);
            setUserFirstName(async () => {
                const firstName = await cryptDecryptDataUser(
                    dataUserConnected.sub.firstname,
                    setUserFirstName as typeof Function
                );
                return firstName;
            });
            setUserLastName(async () => {
                const lastName = await cryptDecryptDataUser(
                    dataUserConnected.sub.name,
                    setUserLastName as typeof Function
                );
                return lastName;
            });
        } else {
            setUserFirstName(async () => {
                const fisrtNameLocalStorage: any =
                    localStorage.getItem("firstName");
                const firstName = await cryptDecryptDataUser(
                    fisrtNameLocalStorage,
                    setUserFirstName as typeof Function
                );

                return firstName;
            });
            setUserLastName(async () => {
                const lastNameLocalStorage: any =
                    localStorage.getItem("lastName");
                const lastName = await cryptDecryptDataUser(
                    lastNameLocalStorage,
                    setUserLastName as typeof Function
                );

                return lastName;
            });
        }
    }, [tokenUserTmp, cryptDecryptDataUser]);

    React.useEffect(() => {
        if (userFirstName !== "" && userLastName !== "") {
            const fullName = `${userFirstName} ${userLastName}`;
            setName(fullName);
        } else if (localStorage.getItem("authUser")) {
            if (
                process.env.REACT_APP_DEFAULTAUTH === "fake" ||
                process.env.REACT_APP_DEFAULTAUTH === "jwt"
            ) {
                const obj = JSON.parse(localStorage.getItem("authUser")!);
                setName(
                    `${
                        obj.firstname
                            ? `${obj.firstname} ${obj.lastname}`
                            : obj.username
                    }`
                );
            }
        }
    }, [userFirstName, userLastName]);

    function logout(): any {
        cache.clear();
        localStorage.clear();
        navigate("/login");
    }

    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item waves-effect  drop-down__clz"
                    id="page-header-user-dropdown"
                    tag="button"
                >
                    <Avatar name={name} size="40" round />
                    {menu !== true ? (
                        <BsCaretDownFill className="pt-2" fill="white" />
                    ) : (
                        <BsCaretUpFill className="pt-2" fill="white" />
                    )}
                </DropdownToggle>
                <DropdownMenu right>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => logout()}
                        className="dropdown-item"
                    >
                        <span>{t("Logout")}</span>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
}
