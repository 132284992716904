import React from "react";
import { Navigate } from "react-router-dom";

import { SpinnerWrapper } from "@components/common/SpinnerWrapper";
import Home from "@pages/Home";
import Login from "@pages/Authentication/Login";
import { Callback } from "@pages/Authentication/Callback";
import Layout from "@components/Layout";

import LoginAuthRoute from "./middleware/LoginAuthRoute";

export const routes = [
    {
        element: (
            <LoginAuthRoute>
                <React.Suspense fallback={<SpinnerWrapper />}>
                    <Layout />
                </React.Suspense>
            </LoginAuthRoute>
        ),
        children: [
            { path: "/", element: <Home /> },
            { path: "/home", element: <Home /> },
            { path: "/installer", element: <Home /> },
        ],
    },
    {
        element: <Login />,
        path: "login",
    },
    {
        element: <Callback />,
        path: "/Callback",
    },
    {
        path: "*",
        element: <Navigate to="/home" />,
    },
];
